import React, { Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";

import { useStyles } from "../styles";

import { SideMenu } from "./SideMenu";
import { Header } from "./Header";
import { Loader } from "./Loader";
import { useAuth } from "context/auth";

import routeParams from "../config/routes";

import {
  required,
  objectRequired,
  dateNotPast,
  objectValuesRequired,
} from "utils/validators";

import Labels from "containers/Labels";

import BookingsTable from "../containers/BookingsTable";

// const CreateBooking = React.lazy(() => import("../containers/CreateBooking"));
// const QuickForm = React.lazy(() => import("../containers/QuickForm"));
// const BookingDetails = React.lazy(() => import("../containers/BookingDetails"));
// const Settings = React.lazy(() => import("../containers/Settings"));
// const Help = React.lazy(() => import("./Help"));

import CreateBooking from "containers/CreateBooking";
import QuickForm from "containers/QuickForm";
import BookingDetails from "containers/BookingDetails";
import Settings from "containers/Settings";
import Help from "components/Help";
import Report from "components/Report";
import { SentryRoute } from "index";
import { getAvailableRoutes } from "utils/policies";

export default () => {
  const location = useLocation();

  const { user } = useAuth();
  const styles = useStyles({
    minimizeMenu: Boolean(user?.appConfig?.minimizeMenu),
  });
  const availableRoutes = getAvailableRoutes(user?.policies);
  const mainRoute = availableRoutes[0];
  React.useEffect(() => {
    //location policy validation
    if (!availableRoutes.find((route) => route.path === location.pathname)) {
      window.location.href = mainRoute.path;
    }
  }, [location, user]);

  console.log("mainRoute", mainRoute);

  const loader = <Loader color="primary" size={50} margin={10} loading />;
  return (
    <Box className={styles.root} display="flex">
      <Box className="width-side-menu" zIndex={200} position="fixed">
        <SideMenu />
      </Box>
      <Box className="width-body">
        <Header />
        <Box ml={{ xs: 1, lg: 2 }} mr={1}>
          {/* We leave BookingsTable outside of the switch because 
          we want to persist it's state between pages switch 
          (between BookingOverview and booking details switch) */}
          <Route
            exact
            path={routeParams.bookings.path}
            children={(props) => <BookingsTable routerProps={props} />}
          ></Route>
          <Switch>
            <Route exact path={routeParams.mainPage.path}>
              <Redirect to={mainRoute.path} />
            </Route>
            <Route exact path={routeParams.bookingDetails.path}>
              <BookingDetails />
            </Route>
            <SentryRoute exact path={routeParams.createBooking.path}>
              <CreateBooking
                key="create-booking-container" //we need keys for rerendering containers when path changed
                ignoredFields={[
                  "bookingId",
                  "isLocal",
                  "userId",
                  "deadline",
                  "tourTime",
                  "customerNb",
                  "customersCustomer",
                  "deadline",
                  "ediReference",
                  "movementStatus",
                  "actualPickup",
                  "actualDelivery",
                  "trackingNumber",
                ]}
                validators={{
                  pickupDate: [required, dateNotPast],
                  pickupTime: [dateNotPast],
                  deliveryDate: [dateNotPast],
                  deliveryTime: [dateNotPast],
                  latestDeliveryDate: [dateNotPast],
                  latestDeliveryTime: [dateNotPast],
                  customer: [required],
                  pickup: [required],
                  receiver: [required],
                  delivery: [required],
                  goods: [objectRequired, objectValuesRequired],
                }}
                fullWidth
              />
            </SentryRoute>
            <Route exact path={routeParams.editBooking.path}>
              <CreateBooking
                key="edit-booking-container"
                ignoredFields={[
                  "bookingId",
                  "isLocal",
                  "userId",
                  "deadline",
                  "tourTime",
                  "customerNb",
                  "customersCustomer",
                  "deadline",
                  "ediReference",
                  "movementStatus",
                  "actualDelivery",
                  "actualPickup",
                  "trackingNumber",
                ]}
                validators={{
                  pickupDate: [required, dateNotPast],
                  pickupTime: [dateNotPast],
                  deliveryDate: [dateNotPast],
                  deliveryTime: [dateNotPast],
                  latestDeliveryDate: [dateNotPast],
                  latestDeliveryTime: [dateNotPast],
                  customer: [required],
                  pickup: [required],
                  receiver: [required],
                  delivery: [required],
                  goods: [objectRequired, objectValuesRequired],
                }}
                fullWidth
              />
            </Route>
            <Route exact path={routeParams.quickForm.path}>
              <QuickForm
                key="quick-form-container"
                muiltiFormFields={[
                  "pickupTime",
                  "tourTime",
                  "pickupDate",
                  "latestDeliveryTime",
                  "latestDeliveryDate",
                  "deliveryDate",
                  "deliveryTime",
                ]}
                //requiredFields={["pickupDate", "goods"]}
                validators={{
                  pickupDate: [required, dateNotPast],
                  pickupTime: [dateNotPast],
                  deliveryDate: [dateNotPast],
                  deliveryTime: [dateNotPast],
                  latestDeliveryDate: [dateNotPast],
                  latestDeliveryTime: [dateNotPast],
                  //goods: [objectRequired, objectValuesRequired],
                }}
                ignoredColumns={[
                  "userId",
                  "bookingId",
                  "isLocal",
                  "customersCustomer",
                  //"customerNb",
                  "status",
                  "deadline",
                  "type",
                  "tourTime",
                  "goods",
                  "packages",
                  "ediReference",
                  "movementStatus",
                  "actualDelivery",
                  "actualPickup",
                  "trackingNumber",
                ]}
              />
            </Route>
            <Route path={routeParams.settings.path}>
              <Settings />
            </Route>
            <Route path={routeParams.help.path}>
              <Help />
            </Route>
            <Route exact path={routeParams.report.path}>
              <Report />
            </Route>
            {user.allowIndtrans && (
              <>
                <Route exact path={routeParams.createLocalBooking.path}>
                  <CreateBooking
                    key="create-local-booking-container"
                    showBookingsList
                    defaultBookingParams={{ isLocal: true }}
                    ignoredFields={[
                      "bookingId",
                      "userId",
                      "pickupDate",
                      "pickupTime",
                      "latestDeliveryDate",
                      "latestDeliveryTime",
                      // "packages",
                      "isLocal",
                      //"departureDate",
                      "customerNb",
                      "customersCustomer",
                      "deadline",
                      "ediReference",
                      "movementStatus",
                      "actualDelivery",
                      "actualPickup",
                      "trackingNumber",
                    ]}
                    validators={{
                      tourTime: [required],
                      customer: [required],
                      pickup: [required],
                      receiver: [required],
                      delivery: [required],
                      goods: [objectRequired, objectValuesRequired],
                    }}
                    fullWidth
                  />
                </Route>
                <Route exact path={routeParams.editLocalBooking.path}>
                  <CreateBooking
                    key="edit-local-booking-container"
                    defaultBookingParams={{ isLocal: true }}
                    ignoredFields={[
                      "userId",
                      "pickupDate",
                      "pickupTime",
                      "latestDeliveryDate",
                      "latestDeliveryTime",
                      // "packages",
                      "isLocal",
                      //"departureDate",
                      "customerNb",
                      "customersCustomer",
                      "deadline",
                      "ediReference",
                      "movementStatus",
                      "actualDelivery",
                      "actualPickup",
                      "trackingNumber",
                    ]}
                    validators={{
                      tourTime: [required],
                      customer: [required],
                      pickup: [required],
                      receiver: [required],
                      delivery: [required],
                      goods: [objectRequired, objectValuesRequired],
                    }}
                    fullWidth
                  />
                </Route>
                <Route exact path={routeParams.quickFormLocal.path}>
                  <QuickForm
                    key="quick-form-local-container"
                    local
                    ignoredColumns={[
                      "bookingId",
                      "isLocal",
                      "userId",
                      "deadline",
                      "type",
                      "customersCustomer",
                      //"customerNb",
                      "pickupDate",
                      "pickupTime",
                      "latestDeliveryDate",
                      "latestDeliveryTime",
                      "status",
                      "goods",
                      "packages",
                      "packagesDescription",
                      "ediReference",
                      "movementStatus",
                      "actualDelivery",
                      "actualPickup",
                      "trackingNumber",
                    ]}
                    muiltiFormFields={[
                      "tourTime",
                      "deliveryTime",
                      "deliveryDate",
                      "latestDeliveryTime",
                      "latestDeliveryDate",
                    ]}
                    validators={{
                      tourTime: [required],
                      pickupTime: [dateNotPast],
                      deliveryDate: [dateNotPast],
                      deliveryTime: [dateNotPast],
                      latestDeliveryDate: [dateNotPast],
                      latestDeliveryTime: [dateNotPast],
                      //goods: [objectRequired, objectValuesRequired],
                    }}
                  />
                </Route>
                <Route exact path={routeParams.labels.path}>
                  <Labels />
                </Route>
              </>
            )}
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};
