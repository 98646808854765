/** rotes are sort from the ones that should be open first to the least popular options */
const config: {
  [pageName: string]: {
    path: `/${string}`;
    hideTitle?: boolean;
    name?: string;
    allowedPolicies?: string[];
  };
} = {
  createBooking: {
    path: "/createBooking",
    allowedPolicies: ["webbooking.full", "bookings.full"],
  },
  report: {
    path: "/report",
    allowedPolicies: ["webbooking.full", "report.full"],
  },

  bookings: {
    path: "/bookings",
    allowedPolicies: ["webbooking.full", "bookings.full"],
  },
  bookingDetails: {
    path: "/bookings/:trackingId",
    name: "bookingDetails",
    allowedPolicies: ["webbooking.full", "bookings.full"],
  },
  bookingDetailsPublic: { path: "/track/:trackingId" },

  editBooking: {
    path: "/editBooking",
    allowedPolicies: ["webbooking.full", "bookings.full"],
  },
  quickForm: {
    path: "/quickForm",
    allowedPolicies: ["webbooking.full", "quickform.full"],
  },
  createLocalBooking: {
    path: "/createLocalBooking",
    allowedPolicies: ["webbooking.full", "quickform.full"],
  },
  editLocalBooking: {
    path: "/editLocalBooking",
    allowedPolicies: ["webbooking.full", "quickform.full"],
  },
  quickFormLocal: {
    path: "/quickFormLocal",
    allowedPolicies: ["webbooking.full", "quickform.full"],
  },
  labels: {
    path: "/labels",
    allowedPolicies: ["webbooking.full", "labels.full"],
  },

  settings: {
    path: "/settings",
    allowedPolicies: ["webbooking.full", "settings.full"],
  },
  help: {
    path: "/help",
    hideTitle: true,
    allowedPolicies: ["webbooking.full", "help.full"],
  },
  login: { path: "/login" },
  mainPage: {
    path: "/",
  },
};

export default config;
