import React from "react";
import Box from "@material-ui/core/Box";
import MenuList from "@material-ui/core/MenuList";
import Button from "@material-ui/core/Button";
import { PropTypes } from "@material-ui/core";

import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";

import { useHistory, useLocation } from "react-router-dom";

import NavFastMenu from "./NavFastMenu";
import { useLang } from "../context/lang";
import { useStyles } from "../styles";
import "hamburgers/dist/hamburgers.min.css";

import logo from "../assets/img/logo.png";
import bookingListIcon from "../assets/img/bookingList.svg";
import createBookingIcon from "../assets/img/createBooking.svg";
import quickFormIcon from "../assets/img/quickForm.svg";
import quickFormLocalIcon from "../assets/img/quickFormLocal.svg";
import createBookingLocalIcon from "../assets/img/createBookingLocal.svg";
import labelIcon from "../assets/img/book.svg";

import arrowLeftCircle from "../assets/img/arrowLeftCircle.svg";
import arrowRightCircle from "../assets/img/arrowRightCircle.svg";

import { useAuth } from "context/auth";

import routesParams from "../config/routes";

import { AppConfig } from "navision-proxy-api/@types";
import { checkPolicy } from "utils/policies";

interface IProps {
  to: `/${string}`;
  text: string;
  startIconPath?: string;
  endIcon?: JSX.Element | string;
  color?: PropTypes.Color;
  minimal?: boolean;
  hide?: boolean;
}

const MenuItemLink = ({
  to,
  text,
  startIconPath,
  endIcon,
  color = "default",
  hide,
  minimal = false,
}: IProps) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const isActive = pathname === to;
  return hide ? (
    <></>
  ) : (
    <Box display="flex">
      <Box display="flex" justifyContent="space-between" clone>
        <Button
          color={isActive ? "secondary" : color}
          className={minimal && isActive ? "active-button" : ""}
          onClick={() => history.push(to)}
          fullWidth
        >
          <Box component="span" display="flex" alignItems="center">
            {startIconPath ? (
              <Box p={1} display="flex" height="25px" justifyContent="center">
                <img width="25" src={startIconPath} />
              </Box>
            ) : (
              <div style={{ width: 0, height: 25 }} /> //empty element
            )}
            <Fade
              in={!minimal}
              timeout={{ enter: 700, exit: 200 }}
              unmountOnExit
            >
              <div
                style={{
                  width: 150,
                  wordBreak: "break-word",
                  whiteSpace: "initial",
                  textAlign: "left",
                }}
              >
                {text}
              </div>
            </Fade>
          </Box>
          <Fade in={!minimal} timeout={{ enter: 700, exit: 200 }}>
            <Box display="flex" alignItems="center">
              {minimal ? undefined : isActive ? (
                <Icon>keyboard_arrow_right</Icon>
              ) : (
                endIcon
              )}
            </Box>
          </Fade>
        </Button>
      </Box>
    </Box>
  );
};

export const SideMenu = () => {
  const [isOpened, setOpened] = React.useState(false);
  const { t } = useLang();
  const { user, setAppUserConfig } = useAuth();

  const appConfig = user?.appConfig;
  const minimizeMenu = Boolean(appConfig?.minimizeMenu);
  const showLabelsMenu = Boolean(appConfig?.showLabelsMenu);

  const styles = useStyles({ minimizeMenu });
  return (
    <Box position="relative" height="100%" pt={{ xs: "40px", sm: 0 }}>
      <Box
        zIndex={201}
        position="absolute"
        alignItems="center"
        left={isOpened ? "155px" : 0}
        width="100vw"
        boxShadow={0}
        top={0}
        onClick={() => setOpened((prev) => !prev)}
        style={{
          transitionDuration: "0.4s",
          transitionDelay: "0.05s",
          cursor: "pointer",
          backgroundColor: isOpened ? undefined : "white",
        }}
        display={{ xs: "flex", lg: "none" }}
      >
        <button
          className={`hamburger hamburger--slider ${
            isOpened ? "is-active" : ""
          }`}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>{" "}
        {!isOpened && (
          <Box display="flex" alignItems="center" clone>
            <Typography variant="h6">{t("menu")}</Typography>
          </Box>
        )}
      </Box>
      <Box
        boxShadow={5}
        className={`${styles.sideMenu} ${isOpened ? "side-menu-opened" : ""}`}
        pt={{ xs: "40px", sm: "1px" }}
        onClick={() => setOpened(false)}
      >
        <Box className={styles.logo}>
          <Box my={4} mx="auto" display="block" clone>
            <img src={logo} />
          </Box>
        </Box>
        <MenuList>
          <MenuItemLink
            to={routesParams.mainPage.path}
            text={t("webbooking")}
            color="primary"
            endIcon={<Icon>keyboard_arrow_down</Icon>}
            minimal={minimizeMenu}
          />
          <Divider />
          <MenuItemLink
            to={routesParams.bookings.path}
            hide={
              !checkPolicy(
                user?.policies,
                routesParams.bookings.allowedPolicies
              )
            }
            text={t("bookings")}
            startIconPath={bookingListIcon}
            minimal={minimizeMenu}
          />
          <MenuItemLink
            to={routesParams.createBooking.path}
            hide={
              !checkPolicy(
                user?.policies,
                routesParams.createBooking.allowedPolicies
              )
            }
            text={t("createBooking")}
            startIconPath={createBookingIcon}
            minimal={minimizeMenu}
          />
          <MenuItemLink
            to={routesParams.quickForm.path}
            hide={
              !checkPolicy(
                user?.policies,
                routesParams.quickForm.allowedPolicies
              )
            }
            text={t("quickForm")}
            startIconPath={quickFormIcon}
            minimal={minimizeMenu}
          />
          {user?.allowIndtrans && (
            <MenuItemLink
              to={routesParams.createLocalBooking.path}
              hide={
                !checkPolicy(
                  user?.policies,
                  routesParams.createLocalBooking.allowedPolicies
                )
              }
              text={t("createLocalBooking")}
              startIconPath={createBookingLocalIcon}
              minimal={minimizeMenu}
            />
          )}
          {user?.allowIndtrans && (
            <MenuItemLink
              to={routesParams.quickFormLocal.path}
              hide={
                !checkPolicy(
                  user?.policies,
                  routesParams.quickFormLocal.allowedPolicies
                )
              }
              text={t("quickFormLocal")}
              startIconPath={quickFormLocalIcon}
              minimal={minimizeMenu}
            />
          )}
          {user?.allowIndtrans && showLabelsMenu && (
            <MenuItemLink
              to={routesParams.labels.path}
              hide={
                !checkPolicy(
                  user?.policies,
                  routesParams.labels.allowedPolicies
                )
              }
              text={t("labels")}
              startIconPath={labelIcon}
              minimal={minimizeMenu}
            />
          )}
          {(user as any)?.powerBIReportConfig && (
            <MenuItemLink
              to={routesParams.report.path}
              hide={
                !checkPolicy(
                  user?.policies,
                  routesParams.report.allowedPolicies
                )
              }
              text={t("report")}
              startIconPath={bookingListIcon}
              minimal={minimizeMenu}
            />
          )}
          {/* <MenuItemLink to={routesParams.settings.path} text={t("settings")} /> */}
          <Divider />
          <Box display={{ xs: "flex", sm: "none" }} justifyContent="flex-end">
            <NavFastMenu slim />
          </Box>
        </MenuList>
        <Box
          width={1}
          position="absolute"
          bottom="0"
          display={{ xs: "none", sm: "flex" }}
          justifyContent="space-between"
        >
          <Button
            onClick={() =>
              setAppUserConfig({
                minimizeMenu: !Boolean(user?.appConfig?.minimizeMenu),
              } as AppConfig)
            }
            fullWidth
          >
            <Box display="flex" alignItems="center" ml={1}>
              {minimizeMenu ? (
                <img src={arrowRightCircle} />
              ) : (
                <img src={arrowLeftCircle} />
              )}
            </Box>
            <Fade in={!minimizeMenu} timeout={{ enter: 700, exit: 200 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="70%"
              >
                {!minimizeMenu && (
                  <>
                    {t("minimizeMenu")}
                    <Icon>keyboard_arrow_left</Icon>
                  </>
                )}
              </Box>
            </Fade>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
